<template>
  <div>
    <cta-banner :title="$route.name"/>
    <div class="bg-white mb-10 mt-5">
      <nav class="flex flex-col sm:flex-row justify-center">
        <button @click="changeSpeaker('Conferencista')"
          :class="typeSpeaker === 'Conferencista' ? 'text-theme-color border-b-2 font-medium border-theme-color' : null"
          class=" py-4 px-6 block hover:text-theme-color focus:outline-none">
          Conferencistas
        </button>
        <button @click="changeSpeaker('Tallerista')"
          :class="typeSpeaker === 'Tallerista' ? 'text-theme-color border-b-2 font-medium border-theme-color' : null"
          class=" py-4 px-6 block hover:text-theme-color focus:outline-none">
          Talleristas
        </button>
        <button @click="changeSpeaker('Ponente')"
          :class="typeSpeaker === 'Ponente' ? 'text-theme-color border-b-2 font-medium border-theme-color' : null"
          class=" py-4 px-6 block hover:text-theme-color focus:outline-none">
          Ponentes
        </button>
      </nav>
    </div>
    <cta-conferencista v-for="speaker in speakers" :key="speaker.nombre" :nombre="speaker.nombre" :tipo="speaker.tipo" :descripcion="speaker.descripcion" :foto="speaker.foto" :universidad="speaker.universidad" :ciudad="speaker.ciudad" :tema="speaker.tema" :link="speaker.link" :cupo="speaker.cupos" />
  </div>
</template>

<script>
import CtaBanner from '../../components/common/CtaBanner.vue';
import CtaConferencista from '../../components/CtaConferencista.vue';
import API from "@/api";
import {mapState} from "vuex";

export default {
  name: "Conferencista",
  components: {
    CtaBanner,
    CtaConferencista
  },
  data() {
    return {
      speakers: [],
      typeSpeaker: "Conferencista",
    };
  },
  computed: {
    ...mapState(["idEventoActual"]),
  },
  created() {
   this.getSpeakers()
  },
  methods: {
    changeSpeaker(speaker) {
      this.typeSpeaker = speaker;
      this.getSpeakers();
    },
    async getSpeakers() {
      const params = {
        "evento_fk" : this.idEventoActual,
        format:  "json",
        tipo: this.typeSpeaker
      };
      const { data } = await API.getSpeakers(params);
      this.speakers = data.results;
    }
  },
};
</script>
