<template>
  <section class="container mx-auto flex lg:flex-row flex-col items-center w-full py-12 px-10">
    <div class="h-72 w-72 mb-10 lg:mb-0">
      <a :href="link" target="_blank">
        <img class="h-full w-full rounded-full" :src="foto" alt="speaker" />
      </a>
    </div>
    <div class="flex-1 items-center text-center lg:text-left">
      <div class=" md:px-9 lg:ml-9 border-b">
        <h5 class="tracking-normal text-theme-color">{{tipo}}</h5>
        <a :href="link" target="_blank">
          <h3 class="text-3xl font-medium mb-2">{{nombre}}</h3>
        </a>
        <div class="flex mb-4 lg:justify-start justify-center">
          <span class="flex items-center" v-if="universidad">
            <span>{{universidad}}</span>
          </span>
          <span class="flex ml-3 pl-3 py-2 border-l-2 border-gray-200 space-x-2s">
            <span v-if="ciudad">{{ciudad}}</span>
          </span>
        </div>
        <p class="mb-8 leading-relaxed" v-if="descripcion !== '.'">
          {{descripcion}}
        </p>
        <div class="mb-10" v-if="tema || cupo">
          <small class="text-gray-500 mr-3" v-if="tema">Temática: </small>
          <span class="inline-block py-1 px-2 mr-3 rounded bg-theme-color-light text-theme-color text-xs font-medium tracking-normal" v-if="tema">{{tema}}</span><br>
          <small class="text-gray-500 mr-3" v-if="cupo">Cupos:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</small>
          <span class="inline-block py-1 px-2 mr-3 rounded bg-theme-color-light text-theme-color text-xs font-medium tracking-normal" v-if="cupo">{{cupo}}</span>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  props: ["nombre", "tipo", "descripcion", "foto", "universidad", "ciudad", "tema", "link", "cupo" ]
};
</script>

<style scoped>
</style>
